import React, { FC, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Tooltip } from '@mui/material';
import { BookmarkAdd, BookmarkAddedOutlined } from '@mui/icons-material';
import { decryptString } from '../app.utils';
import { createBookmark, deleteBookmark, fetchBookmarkDetails } from '../services/bookmarks.service';
import { useLoader } from '../providers';
import { getUserId } from '../services/auth.service';

interface ITopPanel {
  doc: {
    highlighter: Boolean,
    bookmark: Boolean,
    type: string,
    id: string,
    header: string,
    detail: string,
  },
  setDoc: Function,
  justIcon?: boolean,
}

export const TopPanel: FC<ITopPanel> = ({ doc, setDoc, justIcon = false }) => {

  const [isBookmarkFetched, setBookmarkFetched] = useState(false);

  const { showLoader, hideLoader } = useLoader();

  const updateBookmark = (doc: any) => {
    const bookmarkPayload = { type: doc.type, id: doc.id, header: doc.header || 'Bookmark title', detail: doc.detail };
    if (doc.bookmark) {
      removeItfromBookmark(bookmarkPayload, doc.id);
    } else {
      markItAsBookmark(bookmarkPayload);
    }
  }

  const markItAsBookmark = async (data: any) => {
    
    showLoader();

    try {
      const payload = {
        targetId: data.id,
        typeName: data.type,
        userId: getUserId(),
        header: data.header,
        detail: data.detail,
        createdBy: decryptString(localStorage.getItem('username')),
      };
      const response: any = await createBookmark(payload);
      if (response) {
        toast.success('Bookmark created successfully.');
        setDoc({ ...doc, bookmark: true })
      }
    } catch (error) {
      toast.error('Failed to create bookmark.');
    } finally {
      hideLoader();
    }
  }

  const removeItfromBookmark = async (data: any, id: string) => {
    const userId = getUserId();

    showLoader();
    try {
      const response: any = await deleteBookmark(userId, id);
      if (response) {
        if (response.deleted > 0) {
          toast.success('Bookmark deleted successfully.');
          setDoc({ ...data, bookmark: false });
        } else {
          toast.error('Failed to delete bookmark.');
        }
      }
    } catch (error) {
      toast.error('Failed to delete bookmark.');
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    loadBookMarkState();
  }, [doc]);

  const loadBookMarkState = useCallback(async () => {
    if (isBookmarkFetched) return;

    if (!doc) return;

    const targetId = doc.id;
    const type = doc.type;
    const userId = getUserId();

    if (targetId && type) {
      showLoader();

      try {
        const response: any = await fetchBookmarkDetails(userId, targetId, type);

        if (response) {
          setBookmarkFetched(true);
          if (response.data[0]) {
            setDoc({ ...doc, bookmark: true });
          } else {
            setDoc({ ...doc, bookmark: false });
          }
        }
      } catch {
        toast.error('Failed to change bookmark state.');
      } finally {
        hideLoader();
      }
    }
  }, [doc]);

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row justify-end items-center">
        <Tooltip title={doc.bookmark ? 'Click to remove it form the bookmarks.' : 'Click to add it in the bookmarks.'}>
          {
            justIcon ? (
              <div className='cursor-pointer' onClick={() => {
                updateBookmark({ ...doc, bookmark: doc.bookmark })
              }}>
                {doc.bookmark ? <BookmarkAddedOutlined color='primary' /> : <BookmarkAdd color='primary' />}</div>)
              : (<Button variant="outlined" onClick={() => {
                updateBookmark({ ...doc, bookmark: doc.bookmark });
              }}
                endIcon={doc.bookmark ? <BookmarkAddedOutlined /> : <BookmarkAdd />}>
                {doc.bookmark ? 'Bookmarked' : 'Bookmark'}
              </Button>)}
        </Tooltip>
      </div>
    </div>
  )
};