import React from 'react';
import { BlogCard } from '.';

interface BlogSectionProps {
  blogs: any[]; // Define a more specific type based on your blog data structure
  onPreview: (id: string) => void;
  title?: string;
  onSeeMore?: () => void;
  seeMoreAfter?: number;
  showAll?: boolean;
}

export const BlogSection: React.FC<BlogSectionProps> = ({
  blogs,
  onPreview,
  onSeeMore,
  title,
  seeMoreAfter = 4,
  showAll = false,
}) => (
  <div>
    {blogs.length > 0 && (
      <div className="flex flex-col max-w-7xl mx-auto gap-4 border-b border-gray-300 pb-5 px-2">
        {title && (
          <div className="flex flex-row gap-2 items-center">
            <p className="text-xl text-gray-700 lg:text-3xl font-bold border-l-8 border-gray-600 pl-4">{title}</p>
          </div>
        )}
        <div className="flex flex-wrap gap-6">
          {(showAll ? blogs : blogs.slice(0, seeMoreAfter)).map((blog) => (
            <BlogCard key={blog.id} blog={blog} onPreview={onPreview} />
          ))}
        </div>
        {!showAll && blogs.length > seeMoreAfter && (
          <div className="flex justify-start w-full">
            <a className='text-primary hover:bg-red-100 font-semibold cursor-pointer border-2 border-primary px-2 rounded-md' onClick={onSeeMore}>
              See More
            </a>
          </div>
        )}
      </div>
    )}
  </div>
);
