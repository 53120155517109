import React from "react";
import DataTable, { Column } from "./DataTable";
import { Judgment } from "../app.models";
import { Launch } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { concatenateStrings, formatDate } from "../app.utils";
import { constants } from "../app.constants";
import { isAuthorised } from "../services/auth.service";

interface JudgmentsTableProps {
  data: Judgment[];
  pageNo: number;
  totalPages: number;
  pageSize?: number;
  totalRecords: number;
  onPageChange: (newPage: number) => void;
  heightAdjustment: number;
  editOptionEnable?: boolean;
}

export const JudgmentsTable: React.FC<JudgmentsTableProps> = ({
  data = null,
  pageNo,
  totalPages,
  totalRecords,
  onPageChange,
  heightAdjustment,
  pageSize = Number.parseInt(constants.pageSize),
  editOptionEnable = false,
}) => {

  const openJudgment = (item: Judgment) => {
    const url = '/cases/judgment?judgmentId=' + item?._id?.$oid;
    window.open(url, '_blank');
  };

  const openJudgmentForEdit = (item: Judgment) => {
    const url = '/edit/judgment?judgmentId=' + item?._id?.$oid;
    window.open(url, '_blank');
  };

  const columns: Column[] = [
    {
      key: 'appeallant',
      label: 'Party Name',
      renderCell: (row: Judgment, index) => {
        const canOpenJudgment = isAuthorised() || (index != null && index < 3);

        return (
          editOptionEnable ? (
            <div
              className='flex flex-row items-center gap-1 cursor-pointer hover:text-primary'
              onClick={() => {
                if (canOpenJudgment) {
                  openJudgmentForEdit(row);
                }
              }}
            >
              <Tooltip
                title={'Edit: ' + (row.appeallant || 'N/A') + ' vs ' + (row.respondant || 'N/A')}
                placement='right-start'
              >
                <div className='flex flex-row items-center gap-1'>
                  <span className='max-w-[10rem] truncate'>{row.appeallant || 'N/A'}</span>
                  <b>vs</b>
                  <span className='max-w-[10rem] truncate'>{row.respondant || 'N/A'}</span>
                  <div className='cursor-pointer action'>
                    <Launch className='text-primary' sx={{ fontSize: '1rem' }} />
                  </div>
                </div>
              </Tooltip>
            </div>
          ) : (
            <div
              className='flex flex-row items-center gap-1 cursor-pointer hover:text-primary'
              onClick={() => {
                if (canOpenJudgment) {
                  openJudgment(row);
                }
              }}
            >
              <Tooltip
                title={
                  canOpenJudgment
                    ? (row.appeallant || 'N/A') + ' vs ' + (row.respondant || 'N/A')
                    : constants.tooltips.upgradePlan
                }
                placement='right-start'
              >
                <div className='flex flex-row items-center gap-1'>
                  <span className='max-w-[10rem] truncate'>{row.appeallant || 'N/A'}</span>
                  <b>vs</b>
                  <span className='max-w-[10rem] truncate'>{row.respondant || 'N/A'}</span>
                  <div className='cursor-pointer action'>
                    <Launch className='text-primary' sx={{ fontSize: '1rem' }} />
                  </div>
                </div>
              </Tooltip>
            </div>
          )
        );
      }
    },
    { key: 'appeal', label: 'Appeal', maxChars: 50 },
    {
      key: 'citationNames',
      label: 'Reported as',
      renderCell: (row: Judgment) => (
        <div className='flex flex-row items-center gap-1'>
          <Tooltip placement='bottom-start' title={concatenateStrings(row.citationNames) || 'N/A'}>
            <p className='max-w-[18rem] truncate'>
              {row.reported ? (
                concatenateStrings(row.citationNames)
              ) : ('Unreported')}
            </p>
          </Tooltip>
        </div>
      )
    },
    { key: 'court', label: 'Court', maxChars: 50 },
    {
      key: 'judgment_date',
      label: 'Judgment Date',
      renderCell: (row: Judgment) => (
        <span>{formatDate(row.judgment_date?.toString())}</span>
      )
    },
    { key: 'result', label: 'Result', maxChars: 30 },
  ];

  return (
    <div className={`flex flex-col rounded-container gap-3 w-full`}>
      <div className='flex flex-row justify-between items-center pb-0'>
        <p className='text-2xl font-bold'>Judgments</p>
      </div>

      {data && (
        <DataTable
          columns={columns}
          data={data}
          pageNo={pageNo}
          totalPages={totalPages}
          onPageChange={onPageChange}
          heightAdjustment={heightAdjustment}
          pageSize={pageSize}
          totalRecords={totalRecords}
          showRecordsCount={true}
        />
      )}

    </div>
  );
};