import React from 'react';
import { RemoveRedEye } from '@mui/icons-material';
import { formatDateToMonthDayYear } from '../app.utils';

interface BlogCardProps {
  blog: any; // Define a more specific type based on your blog data structure
  onPreview: (id: string) => void;
}

export const BlogCard: React.FC<BlogCardProps> = ({ blog, onPreview }) => (
  <div className="relative w-72 h-72 overflow-hidden rounded border border-slate-200 shadow cursor-pointer" onClick={() => onPreview(blog.id)}>
    <img src={blog.thumbnail} alt="Uploaded" className="w-full h-full object-cover" />
    <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-60 p-2">
      <p className="text-white text-lg font-semibold line-clamp-3">{blog.title}</p>
    </div>
  </div>
);
