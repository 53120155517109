import React from "react";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { isAuthenticated } from "./services/auth.service";
import { PublicLayout, SecureLayout } from "./containers";

function App() {

  return (
    <div className='min-h-screen min-w-full overflow-hidden'>
      {isAuthenticated() ? <SecureLayout /> : <PublicLayout />}
      <ToastContainer
        position='top-center'
        toastClassName="toast"
        hideProgressBar
        limit={1}
        pauseOnHover
        autoClose={3000} /> {/* AutoClose after 2 seconds*/}
    </div>
  );
}

export default App;