import React, { createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { fetchAllBlogsByCategory } from '../services/blogs.service';

const BlogsContext = createContext<any>(null);

export const useBlogs = () => useContext(BlogsContext);

export const BlogsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [newsBlogs, setNewsBlogs] = useState<any[]>([]);
  const [highCourtBlogs, setHighCourtBlogs] = useState<any[]>([]);
  const [supremeCourtBlogs, setSupremeCourtBlogs] = useState<any[]>([]);
  const [suggestedBlogs, setSuggestedBlogs] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    reloadBlogs();
  }, []);

  const reloadBlogs = async () => {
    setLoading(true);

    try {
      const [newsRes, highCourtRes, supremeCourtRes]: [any, any, any] = await Promise.all([
        fetchAllBlogsByCategory('latest-news', 1, 20),
        fetchAllBlogsByCategory('high-courts', 1, 20),
        fetchAllBlogsByCategory('supreme-courts', 1, 20),
      ]);

      setNewsBlogs(newsRes.data);
      setHighCourtBlogs(highCourtRes.data);
      setSupremeCourtBlogs(supremeCourtRes.data);
      setSuggestedBlogs(generateRandomSuggestions([...newsRes.data, ...highCourtRes.data, ...supremeCourtRes.data], 3));
    } catch {
      toast.error('Failed to fetch blogs.');
    } finally {
      setLoading(false);
    }
  };

  const generateRandomSuggestions = (blogs: any[], count: number) => {
    const shuffled = blogs.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  return (
    <BlogsContext.Provider value={{ newsBlogs, highCourtBlogs, supremeCourtBlogs, suggestedBlogs, loading, reloadBlogs }}>
      {children}
    </BlogsContext.Provider>
  );
};
