// Router import
import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ContainerLoader } from '../components';
import { Auth } from '../views/public/auth';
import { BlogsProvider } from '../providers/blogs.provider';

// Lazy load views
const PrivacyPolicy = lazy(() => import('../views/public/privacy-policy'));
const CorporatePricingPolicy = lazy(() => import('../views/public/corporate-pricing-policy'));
const TermsOfUse = lazy(() => import('../views/public/terms-of-use'));
const LegalAwarenessNewsList = lazy(() => import('../views/public/legal-news-list'));
const LegalAwarenessNews = lazy(() => import('../views/public/legal-news'));
const LegalAwarenessNewsByCategory = lazy(() => import('../views/public/legal-news-by-category'));
const LandingScreen = lazy(() => import('../views/public/landing'));
const EmailVerification = lazy(() => import('../views/public/email-verification'));
const PasswordUpdate = lazy(() => import('../views/public/update-password'));
const OrganizationSignup = lazy(() => import('../views/public/organization-signup'));

const PublicRoutes = () => {
  return (
    <Suspense fallback={<ContainerLoader isLoading={true} />}>
      <Routes>
        <Route path='/' element={<LandingScreen />} />
        <Route path='/auth' element={<Auth />} />
        <Route path='/corporate-pricing-policy' element={<CorporatePricingPolicy />} />
        <Route path='/terms-of-use' element={<TermsOfUse />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/legal-news/*' element={
          <BlogsProvider>
            <Routes>
              <Route path='list' element={<LegalAwarenessNewsList />} />
              <Route path='view' element={<LegalAwarenessNews />} />
              <Route path='category' element={<LegalAwarenessNewsByCategory />} />
            </Routes>
          </BlogsProvider>
        } />
        <Route path='/users/verify/:id' element={<EmailVerification />} />
        <Route path='/users/update-password/:id' element={<PasswordUpdate />} />
        <Route path='/:organizationIdParam/signup' element={<OrganizationSignup />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </Suspense>
  );
};

export default PublicRoutes;
