import { deleteRequest, getRequest, postRequest } from "./request-interceptor.service"

export const fetchBookmarks = async (params: any) => {
  return await getRequest('/bookmark', true, {
    params
  });
}

export const fetchBookmarkDetails = async (userId: string, targetId: string, type: string) => {
  return await getRequest(`/bookmark?targetId=${targetId}&typeName=${type}&userId=${userId}`, true);
}

export const deleteBookmark = async (userId: string, bookmarkId: string) => {
  return await deleteRequest(`bookmark?userId=${userId}&bookmarkIds=${bookmarkId}`);
}

export const createBookmark = async (data: any) => {
  return await postRequest('/bookmark', data);
}