import { isAdmin, isDataOperator } from "./auth.service";
import { getRequest, patchRequest, postRequest } from "./request-interceptor.service";

/**
 * Method for getting statutes
 * @param {string} searchTerm
 * @param {srting} pageNo
 * @param {string} type
 * @returns {Promise}
 */
export const getStatutes = async (searchTerm: string, pageNo: number, type: string, pageSize: string) => {
  const url = '/statutes-search/';
  let enableCacheBustingForAdmins = false;
  if (isAdmin() || isDataOperator()) {
    enableCacheBustingForAdmins = true;
  }
  return await getRequest(url, enableCacheBustingForAdmins, {
    params: {
      searchTerm,
      pageNo,
      type,
      pageSize,
    }
  });
}

/**
 * Method for getting statute file by id
 * @param {string} id
 * @returns {Promise}
 */
export const getStatuteFile = async (id: string) => {
  const url = `/statutes-search/statutes-file/${id}`;
  return await getRequest(url, false, {
    responseType: 'blob',
  });
}

/**
 * Method for getting statute detail by id
 * @param {string} id
 * @returns {Promise}
 */
export const getStatuteById = async (id: string) => {
  const url = `/statutes-search/retrieve-whole-statute-by-id/${id}`;
  return await getRequest(url, true);
}

/**
 * Method to create new statute
 * @param id
 * @param data
 * @returns {Promise}
 */
export const createStatute = async (data: FormData) => {
  const url = `/statutes-search`;
  return await postRequest(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
}

export const updateStatute = async (id: string, data: FormData) => {
  const url = `/statutes-search/${id}`;
  return await patchRequest(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
}

export const fetchAllStatutes = async () => {
  return await getRequest('/statutes-search/all');
};