import React from 'react';
import PhoneInputWithCountrySelect, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

interface PhoneNumberFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  defaultCountry?: any;
  value: string;
  touched: boolean;
  error: string;
  onChange: (event: { target: { name: string; value: string } }) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const PhoneNumberField: React.FC<PhoneNumberFieldProps> = ({
  name,
  label,
  placeholder = 'Enter your phone no.',
  required = false,
  defaultCountry = 'PK',
  value,
  touched,
  error,
  onChange,
  onBlur
}) => {
  
  const handlePhoneChange = (phoneValue: string | undefined) => {
    const phoneNumber = phoneValue || '';
    onChange({ target: { name, value: phoneNumber } });
  };

  const validationError = () => {
    if (required && !value) {
      return `${label} is required`;
    }
    if (value && !isValidPhoneNumber(value)) {
      return 'Invalid phone number';
    }
    return error;
  };

  const displayError = validationError();

  return (
    <div className='flex flex-col gap-1 w-full'>
      <label className='text-gray-600'>
        {label}
        {required && <b className="text-primary ml-1">*</b>}
      </label>
      <PhoneInputWithCountrySelect
        className={`w-full px-4 py-2 text-gray-800 bg-white border rounded-[0.4rem] ${touched && displayError ? 'border-[#d32f2f]' : 'border-gray-300'}`}
        defaultCountry={defaultCountry}
        name={name}
        placeholder={placeholder}
        onBlur={onBlur}
        value={value}
        onChange={handlePhoneChange}
        error={!!(displayError && touched)}
      />
      <span className="text-[0.75rem] text-[#d32f2f] mt-[4px] ml-[14px]">{touched ? displayError : ""}</span>
    </div>
  );
};

export default PhoneNumberField;