// Router import
import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ContainerLoader } from '../components';
import { BlogsProvider } from '../providers/blogs.provider';

// Lazy load imports
const DataManagement = lazy(() => import('../views/secure/data-management'));
const Statutes = lazy(() => import('../views/secure/statutes'));
const UsersManagement = lazy(() => import('../views/secure/users-management'));
const LegalNewsManagement = lazy(() => import('../views/secure/legal-news-management'));
const AddJudgment = lazy(() => import('../views/secure/add-judgment'));
const AddLegalNews = lazy(() => import('../views/secure/add-legal-news'));
const AddStatute = lazy(() => import('../views/secure/add-statute'));
const JudgmentsByCourt = lazy(() => import('../views/secure/courts'));
const EditJudgment = lazy(() => import('../views/secure/edit-judgment'));
const EditLegalNews = lazy(() => import('../views/secure/edit-legal-news'));
const JudgmentsByJudge = lazy(() => import('../views/secure/judges'));
const Judgment = lazy(() => import('../views/secure/judgment'));
const SearchResult = lazy(() => import('../views/secure/search-result'));
const Home = lazy(() => import('../views/secure/home'));
const Cases = lazy(() => import('../views/secure/cases'));
const Departments = lazy(() => import('../views/secure/departments'));
const Plans = lazy(() => import('../views/secure/plans'));
const Dashboard = lazy(() => import('../views/secure/dashboard'));
const AddBanner = lazy(() => import('../views/secure/add-banner'));
const BannerManagement = lazy(() => import('../views/secure/banner-management'));
const EditBanner = lazy(() => import('../views/secure/edit-banner'));
const LegalAwarenessNewsList = lazy(() => import('../views/secure/legal-news-list'));
const LegalAwarenessNewsByCategory = lazy(() => import('../views/secure/legal-news-by-category'));
const LegalAwarenessNews = lazy(() => import('../views/secure/legal-news'));
const AddCourt = lazy(() => import('../views/secure/add-court'));
const EditStatute = lazy(() => import('../views/secure/edit-statute'));
const EditUser = lazy(() => import('../views/secure/edit-user'));
const UserProfile = lazy(() => import('../views/secure/user-profile'));

const ProtectedRoutes = () => {
  return (
    <Suspense fallback={<ContainerLoader isLoading={true} />}>
      <Routes>
        <Route path='/home' element={<Home />} />
        <Route path='/cases' element={<Cases />} />
        <Route path='/departments' element={<Departments />} />
        <Route path='/judges' element={<JudgmentsByJudge />} />
        <Route path='/courts' element={<JudgmentsByCourt />} />
        <Route path='/statutes' element={<Statutes />} />
        <Route path='/plans' element={<Plans />} />
        <Route path='/add/judgment' element={<AddJudgment />} />
        <Route path='/edit/judgment' element={<EditJudgment />} />
        <Route path='/home/search-result' element={<SearchResult />} />
        <Route path='/cases/judgment' element={<Judgment />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/manage/judgments' element={<DataManagement />} />
        <Route path='/manage/users' element={<UsersManagement />} />
        <Route path='/manage/judgments/add' element={<AddJudgment />} />
        <Route path='/manage/courts/add' element={<AddCourt />} />
        <Route path='/manage/statutes/add' element={<AddStatute />} />
        <Route path='/manage/statutes/edit' element={<EditStatute />} />
        <Route path='/manage/legal-news' element={<LegalNewsManagement />} />
        <Route path='/manage/legal-news/add' element={<AddLegalNews />} />
        <Route path='/manage/legal-news/edit' element={<EditLegalNews />} />
        <Route path='/manage/banners' element={<BannerManagement />} />
        <Route path='/manage/banners/add' element={<AddBanner />} />
        <Route path='/manage/banners/edit' element={<EditBanner />} />
        <Route path='/legal-news/*' element={
          <BlogsProvider>
            <Routes>
              <Route path='list' element={<LegalAwarenessNewsList />} />
              <Route path='view' element={<LegalAwarenessNews />} />
              <Route path='category' element={<LegalAwarenessNewsByCategory />} />
            </Routes>
          </BlogsProvider>
        } />
        <Route path='/manage/users/edit' element={<EditUser />} />
        <Route path='/user-profile' element={<UserProfile />} />
        <Route path='*' element={<Navigate to='/home' />} />
      </Routes>
    </Suspense>
  );
};

export default ProtectedRoutes;