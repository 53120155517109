import React, { useState } from "react";
import { TextField, Button, Dialog, DialogContent, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DialogCommonProps } from "../app.types";
import { sendContactUsEmail, sendUserFeedbackEmail } from "../services/user.service";
import { useLoader } from "../providers";
import InputField from "../components/InputField";

export const RequestAccountActivation = ({ open, onClose }: DialogCommonProps) => {
  const [formErrors, setFormErrors] = useState<any>({});
  const [touchedFields, setTouchedFields] = useState<any>({});

  const { showLoader, hideLoader } = useLoader();

  const [formValue, setFormValues] = useState({
    name: '',
    email: '',
    message: 'I am trying to sign in to my account, but I am receiving a message stating that my account has expired. I would like to continue using the services and would appreciate it if you could activate my account as soon as possible.',
  });

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    let newErrors: any = {};
    let updatedFormValue = { ...formValue, [name]: value };
    setFormValues(updatedFormValue);
    setFormErrors((prevErrors: any) => {
      return { ...prevErrors, ...newErrors };
    });
  };

  const handleFieldBlur = (event: any) => {
    const { name } = event.target;
    setTouchedFields((prevTouched: any) => {
      return { ...prevTouched, [name]: true };
    });
  };

  const sendEmail = async () => {
    const { email, name, message } = formValue;
    if (email && name && message) {
      showLoader();
      try {
        await sendContactUsEmail({ name, email, message });
        toast.success("Email sent successfully.");
        setFormValues({
          name: '',
          email: '',
          message: 'I am trying to sign in to my account, but I am receiving a message stating that my account has expired. I would like to continue using the services and would appreciate it if you could activate my account as soon as possible.',
        });
        onClose();
      } catch {
        toast.error("Failed to send email.");
      } finally {
        hideLoader();
      }
    }
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <div className="flex justify-end items-center bg-white border-b border-gray-200 py-2 px-4">
        <IconButton
          onClick={onClose}
          size="small"
          className='action'
          sx={{ color: '#D91E25' }}>
          <HighlightOffIcon fontSize='small' />
        </IconButton>
      </div>
      <DialogContent>
        <div className="flex flex-col">
          <form className="flex flex-col gap-5 w-full h-full">
            <p className="text-3xl font-bold mt-1 mb-2 gradient-text-red leading-10">Request account activation</p>
            <InputField
              name='name'
              placeholder='Enter your name address'
              value={formValue.name}
              onChange={handleInputChange}
              onBlur={handleFieldBlur}
              required
              label='Name'
              touched={touchedFields.name}
              error={formErrors.name} />

            <InputField
              name='email'
              placeholder='Enter your email address'
              value={formValue.email}
              onChange={handleInputChange}
              onBlur={handleFieldBlur}
              required
              type='email'
              label='Email'
              touched={touchedFields.email}
              error={formErrors.email} />

            <InputField
              name='message'
              placeholder='Please provide details about your account activation request. Include information such as your account email, any relevant error messages, and the reason for requesting activation. If you have any questions or need further assistance, please let us know.'
              value={formValue.message}
              onChange={handleInputChange}
              onBlur={handleFieldBlur}
              required
              type='message'
              label='Message'
              touched={touchedFields.message}
              error={formErrors.message}
              multiline={true}
              rows={6} />
          </form>
          <div className="mt-5">
            <Button
              className="w-full"
              disabled={(formValue.message.length === 0 || formValue.email.length === 0 || formValue.name.length === 0) || Object.keys(formErrors).length > 0}
              variant="contained"
              color='primary'
              onClick={() => sendEmail()}
            >
              Send Email
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};