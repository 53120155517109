import { UpdateUserDto } from "../app.dtos";
import { getRequest, patchRequest, postRequest, putRequest } from "./request-interceptor.service";

export const upgradeUserPlan = async (data: any) => {
  const url = '/users/upgrade';
  return await postRequest(url, data);
};

export const upgradeUserPlanRequestEmail = async (name: string, selectedPlan: string) => {
  const url = '/users/plan-upgrade-request';
  return await postRequest(url, {
    name,
    planId: selectedPlan,
  });
};

export const getAllPlans = async () => {
  const url = '/users/plans';
  return await getRequest(url);
};

export const getAllUsers = async (searchQuery = '', userType = '', pageNo = 1, pageSize = 100) => {
  const url = `/users?searchQuery=${searchQuery}&role=${userType}&pageNo=${pageNo}&pageSize=${pageSize}`;
  return await getRequest(url, true);
};

export const sendContactUsEmail = async (requestBody: any) => {
  return await postRequest('/users/contact-us', requestBody);
}

export const sendCorporatePlanRequestEmail = async (requestBody: any) => {
  return await postRequest('/users/request-corporate-plan', requestBody);
}

export const verifyUser = async (userId: string) => {
  return await postRequest(`/users/verify/${userId}`);
}

export const resendVerificationEmail = async (userId: string) => {
  return await postRequest(`/users/resend-email/${userId}`);
}

export const updateUserPassword = async (requestBody: any) => {
  return await putRequest('/auth/set-password', requestBody);
}

export const requestPasswordReset = async (requestBody: any) => {
  return await postRequest('/auth/reset-password', requestBody);
}

export const sendUserFeedbackEmail = async (requestBody: any) => {
  return await postRequest('/users/feedback', requestBody);
}

export const getUserById = async (userId: string) => {
  const url = `/users/${userId}`;
  return await getRequest(url, true);
};

export const updateUser = async (userId: string, requestBody: UpdateUserDto) => {
  return await patchRequest(`/users/${userId}`, requestBody);
}