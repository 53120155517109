import React, { useState } from 'react';
import { constants } from '../app.constants';
import { Button, Divider, TextField } from '@mui/material';
import { useLoader } from '../providers';
import { toast } from 'react-toastify';
import { sendContactUsEmail } from '../services/user.service';

export const ContactSection = () => {
  const { showLoader, hideLoader } = useLoader();

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [formErrors, setFormErrors] = useState<any>({});
  const [touchedFields, setTouchedFields] = useState<any>({});

  const handleInputChange = (event: any) => {
    let errors: any = {};
    const { name, value } = event.target;
    switch (name) {
      case 'email': {
        setEmail(value);
        if (!value) {
          errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          errors.email = 'Email is invalid';
        } else {
          delete formErrors.email;
        }
        break;
      }
      case 'name': {
        setName(value);
        if (!value) {
          errors.name = 'Name is required';
        } else {
          delete formErrors.name;
        }
        break;
      }
      case 'message': {
        setMessage(value);
        if (!value) {
          errors.message = 'Message is required';
        } else {
          delete formErrors.message;
        }
        break;
      }
      default:
        break;
    }
    setFormErrors((prevErrors: any) => {
      return { ...prevErrors, ...errors };
    });
  };

  const handleFieldBlur = (event: any) => {
    const { name } = event.target;
    setTouchedFields((prevTouched: any) => {
      return { ...prevTouched, [name]: true };
    });
  };

  const sendEmail = async () => {
    if (isFormValid()) {
      try {
        showLoader();
        await sendContactUsEmail({ name, email, message });
        toast.success("Email sent successfully.");
        setEmail('');
        setName('');
        setMessage('');
      } catch {
        toast.error("Failed to send email.");
      } finally {
        hideLoader();
      }
    }
  };

  const isFormValid = () => {
    return name.length > 0 && email.length > 0 && message.length > 0 && Object.keys(formErrors).length === 0;
  }

  return (
    <div id='contact' className='bg-gradient-to-t from-[#41090b] to-[#150303] p-6 sm:p-12 grid grid-cols-1 lg:grid-cols-3 gap-8 w-full h-full'>
      <div className='lg:col-span-2 flex flex-col text-left h-full'>
        <div className='flex flex-col flex-1 gap-8'>
          <p className='text-2xl sm:text-4xl pb-3 text-white border-b-2 border-b-primary'>Get in touch</p>
          <div className='flex flex-col gap-4 text-white text-base sm:text-lg'>
            <p>If you have any questions or inquiries about our Legal & Business Research Solutions, please don't hesitate to reach out to us. You can get in touch by filling out the <b className='font-bold text-primary'>Contact Us form</b>, or give us a call at <a className='font-bold text-primary cursor-pointer hover:underline'>+92 311 1116670</a> or email us at <a className='font-bold text-primary cursor-pointer hover:underline'>info@eastlaw.pk</a>. Our team of experts is ready to assist you with any queries you may have, whether it's about our product features, pricing, or technical support. We value your feedback and suggestions, so feel free to share them with us.</p>
            <p>Thank you for considering Eastlaw for your legal research needs.</p>
          </div>
        </div>
        {/* <div className='flex flex-row w-full justify-start'>
          <div className='flex flex-col gap-4 items-start'>
            <img src={constants.svgs.jazzCashLogo} className='w-24 sm:w-32' />
            <p className='text-white'>Powered by JazzCash Payments</p>
            <div className='flex flex-row gap-3'>
              <img src={constants.svgs.masterCardLogo} className='w-8 sm:w-12 rounded' />
              <img src={constants.svgs.vizaCardLogo} className='w-8 sm:w-12 rounded' />
            </div>
          </div>
        </div> */}
        <div className='flex flex-col gap-3'>
          <p className='text-white'>Connect with us on our social media channels.</p>
          <div className='flex flex-row gap-4'>
            <a href='https://www.facebook.com/eastlaw.pk'>
              <img className='w-6 sm:w-8 h-auto' src={constants.svgs.facebook} alt='facebook' />
            </a>
            <a href='https://pk.linkedin.com/in/east-law-506aaa148'>
              <img className='w-6 sm:w-8 h-auto' src={constants.svgs.linkedId} alt='linkedId' />
            </a>
            <a href='https://twitter.com/EastLawpk'>
              <img className='w-6 sm:w-8 h-auto' src={constants.svgs.twitter} alt='twitter' />
            </a>
            <a href='https://www.instagram.com/eastlawpk/'>
              <img className='w-6 sm:w-8 h-auto' src={constants.svgs.instagram} alt='instagram' />
            </a>
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-6 h-full'>
        <form className='flex flex-col gap-4 bg-white p-5 rounded-xl shadow-lg'>
          <p className='text-2xl sm:text-3xl font-bold gradient-text-red'>Contact Us</p>
          <div className='flex flex-col gap-2'>
            <label className='text-gray-500'>Name<b className='text-primary ml-1'>*</b></label>
            <TextField
              size='small'
              name='name'
              placeholder='Enter your name address'
              value={name}
              onChange={handleInputChange}
              onBlur={handleFieldBlur}
              error={!!(formErrors.name && touchedFields.name)}
              helperText={touchedFields.name ? formErrors.name : ''}
              required
            />
          </div>
          <div className='flex flex-col gap-2'>
            <label className='text-gray-500'>Email<b className='text-primary ml-1'>*</b></label>
            <TextField
              size='small'
              name='email'
              placeholder='Enter your email address'
              value={email}
              onChange={handleInputChange}
              onBlur={handleFieldBlur}
              error={!!(formErrors.email && touchedFields.email)}
              helperText={touchedFields.email ? formErrors.email : ''}
              required
            />
          </div>
          <div className='flex flex-col gap-2'>
            <label className='text-gray-500'>Message<b className='text-primary ml-1'>*</b></label>
            <TextField
              rows={6}
              multiline={true}
              required
              size="small"
              id="message"
              name="message"
              placeholder="Please describe your query here."
              value={message}
              onChange={handleInputChange}
              onBlur={handleFieldBlur}
              error={!!(formErrors.message && touchedFields.message)}
              helperText={touchedFields.message ? formErrors.message : ""}
            />
          </div>
          <Divider />
          <Button
              className="w-full mt-4"
              disabled={!isFormValid()}
              variant="contained"
              color='primary'
              onClick={sendEmail}
            >
              Send Email
            </Button>
        </form>
      </div>
    </div>
  )
};