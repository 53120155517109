import { TextField, Button, Dialog, DialogContent, IconButton } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DialogCommonProps } from "../app.types";
import { requestPasswordReset } from "../services/user.service";

export const PasswordReset = ({ open, onClose }: DialogCommonProps) => {
  const [email, setEmail] = useState("");
  const [formErrors, setFormErrors] = useState<any>({});
  const [touchedFields, setTouchedFields] = useState<any>({});

  const handleInputChange = (event: any) => {
    let errors: any = {};
    const { name, value } = event.target;
    switch (name) {
      case "email": {
        if (value.length <= 100) {
          setEmail(value);
        }
        if (!value) {
          errors.email = "Email Subject is required";
        }
        else {
          delete formErrors.email;
        }
        break;
      }
      default:
        break;
    }
    setFormErrors((prevErrors: any) => {
      return { ...prevErrors, ...errors };
    });
  };

  const handleFieldBlur = (event: any) => {
    const { name } = event.target;
    setTouchedFields((prevTouched: any) => {
      return { ...prevTouched, [name]: true };
    });
  };

  const resetPassword = async () => {
    if (email) {
      try {
        const response = await requestPasswordReset({ email });
        if (response) {
          toast.success("Please check your email!");
          setEmail('');
          onClose();
        }
      } catch (error) {
        toast.error("Failed to send email.");
      }
    }
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <div className="flex justify-end items-center bg-white border-b border-gray-200 py-2 px-4">
        <IconButton
          onClick={onClose}
          size="small"
          className='action'
          sx={{ color: '#D91E25' }}>
          <HighlightOffIcon fontSize='small' />
        </IconButton>
      </div>
      <DialogContent>
        <div className="flex flex-col gap-6">
          <p className="text-3xl font-bold gradient-text-red">Reset your Password</p>
          <div className="flex flex-col gap-2">
            <label className='text-gray-500'>Enter your Email address and we will send you a link to reset your password.</label>
            <TextField
              size="small"
              required
              name="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleInputChange}
              onBlur={handleFieldBlur}
              error={!!(formErrors.email && touchedFields.email)}
              helperText={
                touchedFields.email ? formErrors.email : ""
              }
            />
          </div>
          <div>
            <Button
              className="w-full mt-4"
              disabled={(email.length === 0) || Object.keys(formErrors).length > 0}
              variant="contained"
              color='primary'
              onClick={() => resetPassword()}
            >
              Reset Password
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>

  );
};