import React, { memo, useMemo } from 'react';

type NameIconProps = {
  name: string;
  size?: 'small' | 'medium' | 'large' | 'xl';
};

const getColorFromName = (name: string): string => {
  const colors = [
    'bg-green-500 border-green-200 text-green-200',
    'bg-blue-500 border-blue-200 text-blue-200',
    'bg-yellow-500 border-yellow-200 text-yellow-200',
    'bg-purple-500 border-purple-200 text-purple-200',
    'bg-pink-500 border-pink-200 text-pink-200'
  ];
  const hash = name.split('').reduce((acc, char) => char.charCodeAt(0) + acc, 0);
  return colors[hash % colors.length];
};

const getInitials = (name: string): string => {
  const nameArray = name.trim().split(' ');
  return nameArray.length === 1
    ? nameArray[0][0].toUpperCase()
    : (nameArray[0][0] + nameArray[1][0]).toUpperCase();
};

const getSizeClasses = (size: 'small' | 'medium' | 'large' | 'xl'): string => {
  switch (size) {
    case 'small':
      return 'w-10 h-10 text-lg';
    case 'medium':
      return 'w-12 h-12 text-2xl';
    case 'large':
      return 'w-14 h-14 text-3xl';
    case 'xl':
      return 'w-24 h-24 text-5xl';
    default:
      return 'w-12 h-12 text-2xl';
  }
};

const NameIcon: React.FC<NameIconProps> = ({ name, size = 'medium' }) => {
  const initials = useMemo(() => getInitials(name), [name]);
  const colorClass = useMemo(() => getColorFromName(name), [name]);
  const sizeClasses = getSizeClasses(size);

  return (
    <div className={`flex items-center justify-center rounded-full ${colorClass} ${sizeClasses} border-2`}>
      <span className="font-semibold">{initials}</span>
    </div>
  );
};

export default memo(NameIcon);
