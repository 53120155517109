import { deleteRequest, getRequest, postRequest, putRequest } from "./request-interceptor.service";

export const createBlog = async (requestBody: any) => {
  return await postRequest('/blogs/create', requestBody);
}

export const fetchAllBlogs = async (pageNumber: number, pageSize = 100) => {
  return await getRequest(`/blogs?pageNo=${pageNumber}&pageSize=${pageSize}`);
}

export const fetchAllBlogsByCategory = async (category: string, pageNumber = 1, pageSize = 100) => {
  return await getRequest(`/blogs?pageNo=${pageNumber}&pageSize=${pageSize}&category=${category}`);
}

export const fetchBlogById = async (blogId: string , isAuthorized = false) => {
  return await getRequest(`/blogs/${blogId}?isAuthorized=${isAuthorized}`);
}

export const deleteBlog = async (blogId: string) => {
  return await deleteRequest(`/blogs/${blogId}`);
}

export const updateBlog = async (blogId: string, requestBody: any) => {
  return await putRequest(`/blogs/${blogId}`, requestBody);
}