import { enableCacheBustingForAdmins } from "../app.utils";
import { deleteRequest, getRequest, postRequest, putRequest } from "./request-interceptor.service";

export const createBanner = async (requestBody: any) => {
  return await postRequest('/banners/create', requestBody);
}

export const fetchAllBanners = async (pageNumber: number, pageSize: number = 100) => {
  return await getRequest(`/banners?pageNo=${pageNumber}&pageSize=${pageSize}`, enableCacheBustingForAdmins());
}

export const fetchActiveBanners = async () => {
  return await getRequest('/banners/active', true);
}

export const fetchBannerById = async (blogId: string , isAuthorized = false) => {
  return await getRequest(`/banners/${blogId}?isAuthorized=${isAuthorized}`, enableCacheBustingForAdmins());
}

export const deleteBanner = async (blogId: string) => {
  return await deleteRequest(`/banners/${blogId}`);
}

export const updateBanner = async (blogId: string, requestBody: any) => {
  return await putRequest(`/banners/${blogId}`, requestBody);
}