import React, { useEffect, useState } from 'react';
import { Button, FormControl, MenuItem, Select } from '@mui/material';
import { toast } from 'react-toastify';
import { useLoader } from '../../providers';
import { fetchOrganizationTypes } from '../../services/organization.service';
import InputField from '../../components/InputField';
import PhoneNumberField from '../../components/PhoneNumberField';
import { signUp } from '../../services/auth.service';

const SignUp = () => {
  const [orgTypes, setOrgTypes] = useState<any>([]);
  const [formValue, setFormValues] = useState({
    loginFirstName: '',
    loginLastName: '',
    loginEmail: '',
    loginPhone: '',
    loginOrg: '',
    loginOrgType: '',
    loginCity: '',
    loginCountry: '',
  });
  const [formErrors, setFormErrors] = useState<any>({});
  const [touchedFields, setTouchedFields] = useState<any>({});
  const { showLoader, hideLoader } = useLoader();

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    let newErrors: any = {};
    let updatedFormValue = { ...formValue, [name]: value };
    setFormValues(updatedFormValue);
    setFormErrors((prevErrors: any) => {
      return { ...prevErrors, ...newErrors };
    });
  };

  const handleFieldBlur = (event: any) => {
    const { name } = event.target;
    setTouchedFields((prevTouched: any) => {
      return { ...prevTouched, [name]: true };
    });
  };

  const resetFormFields = (organizationId: string) => {
    setFormValues({
      loginFirstName: '',
      loginLastName: '',
      loginEmail: '',
      loginPhone: '',
      loginOrg: '',
      loginOrgType: organizationId,
      loginCity: '',
      loginCountry: ''
    });
    setFormErrors({});
    setTouchedFields({});
  }

  const onSignUp = async (e: any) => {
    e.preventDefault();
    try {
      showLoader();
      const response = await signUp(formValue);
      if (response) {
        resetFormFields(orgTypes[0]?.id);
        toast.success('User Created successfully.\nWe have sent you a verification email please verify to continue.');
      }
    } catch (error: any) {
      if (error.response?.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error('Failed to create the user');
      }
    } finally {
      hideLoader();
    }
  };

  const isFormInvalid = () => {
    if (Object.keys(formErrors).length > 0 || !formValue.loginFirstName ||
      !formValue.loginLastName || !formValue.loginEmail || !formValue.loginPhone ||
      !formValue.loginOrg ||
      !formValue.loginOrgType || !formValue.loginCity ||
      !formValue.loginCountry) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    loadOrgTypes();
  }, []);

  const loadOrgTypes = async () => {
    try {
      showLoader();
      const response: any = await fetchOrganizationTypes();
      if (response && response.length > 0) {
        setOrgTypes(response);
        resetFormFields(response[0]?.id);
      }
    } catch (error) {
      toast.error('Failed to load organization types.');
    } finally {
      hideLoader();
    }
  }

  return (
    <form className='flex flex-col gap-4 bg-white' onSubmit={onSignUp}>
      <p className='text-3xl font-bold my-2 gradient-text-red'>Register your account</p>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
        <InputField
          name='loginFirstName'
          placeholder='Enter your First Name'
          value={formValue.loginFirstName}
          onChange={handleInputChange}
          onBlur={handleFieldBlur}
          required
          label='First Name'
          touched={touchedFields.loginFirstName}
          error={formErrors.loginFirstName} />
        <InputField
          name='loginLastName'
          placeholder='Enter your Last Name'
          value={formValue.loginLastName}
          onChange={handleInputChange}
          onBlur={handleFieldBlur}
          required
          label='Last Name'
          touched={touchedFields.loginLastName}
          error={formErrors.loginLastName} />
        <InputField
          name='loginEmail'
          placeholder='Enter your Email'
          value={formValue.loginEmail}
          onChange={handleInputChange}
          onBlur={handleFieldBlur}
          required
          label='Email'
          type='email'
          touched={touchedFields.loginEmail}
          error={formErrors.loginEmail} />
        <PhoneNumberField
          name='loginPhone'
          label='Phone Number'
          required={true}
          value={formValue.loginPhone}
          touched={touchedFields.loginPhone}
          error={formErrors.loginPhone}
          onChange={handleInputChange}
          onBlur={handleFieldBlur}
        />
        <InputField
          name='loginOrg'
          placeholder='Enter your Company Name'
          value={formValue.loginOrg}
          onChange={handleInputChange}
          onBlur={handleFieldBlur}
          required
          label='Company Name'
          touched={touchedFields.loginOrg}
          error={formErrors.loginOrg} />
        <FormControl className='flex flex-col w-full gap-2'>
          <label className='text-gray-500'>Profession<b className="text-primary ml-1">*</b></label>
          <Select
            size='small'
            name='loginOrgType'
            onChange={handleInputChange}
            value={formValue.loginOrgType || ''}
            MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
            required
            onBlur={handleFieldBlur}
            error={!!(formErrors.loginOrgType && touchedFields.loginOrgType)}
          >
            {orgTypes.map((orgType: any) => (
              <MenuItem key={orgType.id} value={orgType.id}>{orgType.OrganizationType}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <InputField
          name='loginCity'
          placeholder='Enter your City'
          value={formValue.loginCity}
          onChange={handleInputChange}
          onBlur={handleFieldBlur}
          required
          label='City'
          touched={touchedFields.loginCity}
          error={formErrors.loginCity} />
        <InputField
          name='loginCountry'
          placeholder='Enter your Country'
          value={formValue.loginCountry}
          onChange={handleInputChange}
          onBlur={handleFieldBlur}
          required
          label='Country'
          touched={touchedFields.loginCountry}
          error={formErrors.loginCountry} />
      </div>
      <p className='text-gray-500'>By clicking on the Register button, you agree to <a className='font-bold text-primary cursor-pointer hover:underline' onClick={() => { window.open('/terms-of-use', '_blank', 'noopener,noreferrer') }}>our terms</a> and <a className='font-bold text-primary cursor-pointer hover:underline' onClick={() => { window.open('/privacy-policy', '_blank', 'noopener,noreferrer') }}>privacy policy</a>.</p>
      <div className='mt-3'>
        <Button
          type='submit'
          className='w-full'
          disabled={isFormInvalid()}
          variant='contained'
          color='primary'
          onClick={onSignUp}
        >
          Register
        </Button>
      </div>
    </form>
  );
};

export default SignUp;
