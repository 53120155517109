import React from 'react';
import { Autocomplete, TextField, AutocompleteRenderInputParams, FormControl, Checkbox, FilterOptionsState } from '@mui/material';

export interface AutoCompleteOption {
  id: string;
  [key: string]: any;
  count?: number;
}

interface AutoCompleteProps {
  options: AutoCompleteOption[];
  value: AutoCompleteOption[];
  onChange: (event: React.SyntheticEvent, value: AutoCompleteOption[]) => void;
  placeholder?: string;
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  multiple?: boolean;
  labelKey?: string;
  maxRecords?: number;
  disabled?: boolean;
}

export const AutoComplete: React.FC<AutoCompleteProps> = ({
  options,
  value,
  onChange,
  placeholder = '',
  size = 'medium',
  fullWidth = false,
  multiple = false,
  labelKey = 'name',
  maxRecords = 25,
  disabled = false,
}) => {
  const isSelected = (option: AutoCompleteOption) => value.some((v) => v.id === option.id);

  const getLabel = (option: AutoCompleteOption) => option[labelKey] || '';

  const filterOptions = (options: AutoCompleteOption[], state: FilterOptionsState<AutoCompleteOption>): AutoCompleteOption[] => {
    const { inputValue } = state;

    if (!options || !Array.isArray(options)) return [];

    const filteredOptions = options.filter((option: AutoCompleteOption) =>
      getLabel(option).toLowerCase().includes(inputValue.toLowerCase()) ||
      option.id.toLowerCase().includes(inputValue.toLowerCase())
    );

    const limitedOptions = filteredOptions.slice(0, maxRecords);

    return limitedOptions.map((option: AutoCompleteOption) => ({
      ...option,
      label: getLabel(option),
    }));
  };

  return (
    <FormControl fullWidth={fullWidth} size={size}>
      <Autocomplete
        multiple={multiple}
        options={options || []}
        getOptionLabel={getLabel}
        disabled={disabled}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={value}
        onChange={(event, value) => onChange(event, value as AutoCompleteOption[])}
        filterOptions={(options, state) => filterOptions(options, state)}
        size={size}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            placeholder={placeholder}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} className='border-[0.5px] border-gray-100 py-1 px-2 flex items-center cursor-pointer'>
            {multiple && (
              <Checkbox
                checked={isSelected(option)}
                size='small'
                sx={{
                  paddingY: 0,
                  marginRight: 1,
                }}
              />
            )}
            {/* <p>{option.count && (<span className='border border-primary px-1 rounded-full text-sm font-bold text-primary'>{option.count}</span>)} {getLabel(option)}</p> */}
            <p>{getLabel(option)}</p>
          </li>
        )}
      />
    </FormControl>
  );
};
