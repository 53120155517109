import { Bookmark, BookmarkAddOutlined, Download, HighlightOff } from "@mui/icons-material";
import { Dialog, DialogContent, IconButton, CircularProgress, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { BookmarkTypes } from "../app.enums";
import { getStatuteFile } from "../services/statutes.service";
import { PDFViewer } from "../components";
import { getUserId, isAdmin, isDataOperator } from "../services/auth.service";
import { createBookmark, deleteBookmark, fetchBookmarkDetails } from "../services/bookmarks.service";
import { downloadFile } from "../app.utils";

type StatuteDocumentPreviewDialogProps = {
  open: boolean;
  onClose: () => void;
  statute: {
    selectedStatute: string,
    selectedStatuteTitle: string,
    selectedStatuteAct: string,
  }
};

export const StatuteDocumentPreview = ({ open, onClose, statute }: StatuteDocumentPreviewDialogProps) => {
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bookmark, setBookmark] = useState('');
  const [pdfData, setPdfData] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      getBookmarkState();
      loadStatuteFile();
    }
  }, [open]);

  const loadStatuteFile = async () => {
    setLoading(true);

    try {
      const response: any = await getStatuteFile(statute.selectedStatute);

      if (response) {
        setPdfData(response);
      } else {
        toast.error('Failed to fetch statute document.');
      }
    } catch {
      toast.error('Failed to fetch statute document.');
    } finally {
      setLoading(false);
    }
  };

  const bookmarkStatute = async () => {

    try {
      await createBookmark({
        header: statute.selectedStatuteTitle,
        detail: statute.selectedStatuteAct,
        targetId: statute.selectedStatute,
        userId: getUserId(),
        typeName: BookmarkTypes.STATUTE,
      });
      toast.success('Bookmark created successfully.');
      setIsBookmarked(true);
    } catch {
      toast.error('Failed to bookmark statute.');
    }
  };

  const handleDeleteBookmark = async () => {
    const userId = getUserId();

    try {
      await deleteBookmark(userId, bookmark);
      toast.success('Bookmark removed successfully.');
      setIsBookmarked(false);
    } catch {
      toast.error('Failed to delete bookmark.');
    } finally {
    }
  };

  const getBookmarkState = async () => {

    try {
      const response: any = await fetchBookmarkDetails(
        getUserId(),
        statute.selectedStatute,
        BookmarkTypes.STATUTE
      );

      if (response && response.data[0]) {
        setIsBookmarked(true);
        setBookmark(response.data[0].id);
      } else {
        setIsBookmarked(false);
      }
    } catch {
      setIsBookmarked(false);
    }
  };

  const handleDownload = () => {
    if (pdfData) {
      downloadFile(pdfData, statute.selectedStatuteTitle, 'pdf');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="statute-dialog-title"
      aria-describedby="statute-dialog-description"
      PaperProps={{
        style: {
          minWidth: '930px',
          height: '75vh',
        },
      }}>
      <div className="flex justify-between items-center bg-white border-b border-gray-200 py-2 px-4">
        <span className="font-bold text-gray-700">Statute Document</span>
        <div className="flex gap-2">
          {(isAdmin() || isDataOperator()) && (
            <IconButton size="small" color="primary" onClick={handleDownload}>
              <Download />
            </IconButton>
          )}
          {isBookmarked ? (
            <IconButton
              onClick={handleDeleteBookmark}
              size="small"
              color="primary"
            >
              <Bookmark fontSize="small" />
            </IconButton>
          ) : (
            <IconButton
              onClick={bookmarkStatute}
              size="small"
              color="primary"
            >
              <BookmarkAddOutlined fontSize="small" />
            </IconButton>
          )}
          <IconButton
            onClick={onClose}
            size="small"
            color="primary"
          >
            <HighlightOff fontSize="small" />
          </IconButton>
        </div>
      </div>
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress color="primary" />
          </Box>
        ) : pdfData ? (
          <PDFViewer blob={pdfData} />
        ) : (
          <div className="flex flex-col justify-center gap-6 items-center h-full w-full">
            <HighlightOff sx={{ color: '#D91E25', fontSize: '9rem' }} />
            <p className="text-xl">Failed to load statute document.</p>
          </div>
        )}
      </DialogContent>
    </Dialog >
  );
};
