import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Stack, Pagination, Button, IconButton } from '@mui/material';
import { isAuthorised, isAdmin, isFreeUser } from '../services/auth.service';
import { RecordCount } from './RecordsCount';
import { constants } from '../app.constants';

export interface Column {
  key: string;
  label: string;
  maxChars?: number;
  renderCell?: (row: any, index?: number) => React.ReactNode;
}

export interface Action {
  label: string;
  handler: (row: any) => void;
  tooltip?: string;
  icon?: any;
  showIconOnly?: boolean;
  forAdminOnly?: boolean;
}

export interface DataTableProps {
  columns: Column[];
  data: any[];
  actions?: Action[];
  pageNo: number;
  totalPages: number;
  totalRecords?: number;
  heightAdjustment: number;
  pageSize: number;
  onPageChange: (newPage: number) => void;
  showRecordsCount?: boolean;
}

const DataTable: React.FC<DataTableProps> = ({
  columns, data, actions, pageNo, totalPages, onPageChange,
  heightAdjustment, pageSize, totalRecords, showRecordsCount = false
}) => {

  const containerHeight = `calc(100vh - ${heightAdjustment}rem - ${!isAuthorised() ? '2.2' : '0'}rem)`;

  const truncateText = (text: string, maxChars: number) => {
    return text.length > maxChars ? text.substring(0, maxChars) + '...' : text;
  };

  const isActionDisabled = (rowIndex: number) => {
    return isFreeUser() && rowIndex >= 3;
  };

  const shouldShowAction = (action: Action) => {
    return !action.forAdminOnly || isAdmin();
  };

  return (
    <div className='flex flex-col gap-2 whitespace-nowrap'>
      <TableContainer style={{ height: containerHeight }} className={`border border-slate-300 shadow data-table-container overflow-auto`}>
        <Table size='small'>
          <TableHead className="bg-gray-300">
            <TableRow className='p-2'>
              {actions && actions.length > 0 ? (
                <>
                  {columns.slice(0, -1).map((column) => (
                    <TableCell key={column.key} className="font-bold">
                      {column.label}
                    </TableCell>
                  ))}
                  {columns.length > 0 && (
                    <TableCell colSpan={2} className="font-bold">
                      {columns[columns.length - 1].label}
                    </TableCell>
                  )}
                </>
              ) : (
                columns.map((column) => (
                  <TableCell key={column.key} className="font-bold">
                    {column.label}
                  </TableCell>
                ))
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? data.map((row, rowIndex) => (
              <TableRow
                hover
                key={rowIndex}
                className="group show-actions-on-hover"
              >
                {columns.map((column) => (
                  <TableCell key={column.key} className={column.renderCell ? 'max-w-max' : ''}>
                    {column.renderCell ? (
                      column.renderCell(row, rowIndex)
                    ) : (
                      column.maxChars && row[column.key]?.length > column.maxChars ? (
                        <Tooltip title={row[column.key]}>
                          <span>{truncateText(row[column.key] || '', column.maxChars)}</span>
                        </Tooltip>
                      ) : (
                        row[column.key] || '-'
                      )
                    )}
                  </TableCell>
                ))}
                {actions && (
                  <TableCell align='right'>
                    <div className='flex flex-row justify-end items-center gap-1'>
                      {actions.map((action, index) => (
                        shouldShowAction(action) && (
                          <div className='action' key={index}>
                            <Tooltip title={isActionDisabled(rowIndex) ? constants.tooltips.upgradePlan : (action.tooltip || '')} placement='bottom' key={index}>
                              <span>
                                {action.showIconOnly ? (
                                  <IconButton
                                    size='small'
                                    color='primary'
                                    disableRipple
                                    disabled={isActionDisabled(rowIndex)}
                                    sx={{ padding: 0 }}
                                    onClick={() => action.handler(row)}
                                  >
                                    {action.icon}
                                  </IconButton>
                                ) : (
                                  <Button
                                    variant='text'
                                    size='small'
                                    sx={{ paddingX: 1, paddingY: 0 }}
                                    className='hover:underline cursor-pointer text-sm flex items-center hover:text-primary'
                                    onClick={() => action.handler(row)}
                                    startIcon={action.icon && action.icon}
                                    disabled={isActionDisabled(rowIndex)}
                                  >
                                    {action.label}
                                  </Button>
                                )}
                              </span>
                            </Tooltip>
                          </div>
                        )
                      ))}
                    </div>
                  </TableCell>
                )}
              </TableRow>
            )) : (
              <TableRow key={-1}>
                <TableCell colSpan={columns.length} align={"center"}>
                  No records found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className='flex flex-row justify-between items-end'>
        {showRecordsCount && (<RecordCount pageNo={pageNo || 1} pageSize={pageSize} totalRecords={totalRecords || 0} />)}
        <div className='flex flex-row w-full justify-end'>
          <Tooltip title={!isAuthorised() ? constants.tooltips.upgradePlan : '' } placement='left'>
            <Stack spacing={2} className='mt-4'>
              <Pagination
                size='small'
                color='primary'
                disabled={!isAuthorised()}
                page={pageNo || 1}
                count={totalPages || 1}
                onChange={(_, newPage) => onPageChange(newPage)}
                shape='rounded'
              />
            </Stack>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default DataTable;
