import { deleteRequest, getRequest, postRequest } from "./request-interceptor.service"

export const fetchSearchHistory = async (params: any) => {
  return await getRequest('/search-history', true, {
    params
  });
}

export const deleteSearchHistory = async (userId: string, historyId: string) => {
  return await deleteRequest(`search-history?userId=${userId}&searchHistoryIds=${historyId}`);
}

export const createSearchHistory = async (data: any) => {
  return await postRequest('/search-history', data);
}