import { decryptString } from "../app.utils";
import { Role } from "../enums";
import { postRequest } from "./request-interceptor.service";

export const getSigninMethods = async (userData: any) => {
  return postRequest('/auth/methods', userData, {
    withCredentials: true,
  });
};

export const signUp = async (userData: any) => {
  return await postRequest('/auth/signup', userData, { withCredentials: true });
};

export const logout = () => {
  localStorage.clear();
  window.location.href = '/';
}

export const isAuthenticated = () => {
  const authenticated = decryptString(localStorage.getItem('isAuthenticated'));
  const isVerified = decryptString(localStorage.getItem('isVerified'));
  return authenticated && isVerified;
}

export const currentUserRole = (): Role => {
  const userRole = decryptString(localStorage.getItem('role'));
  if (userRole) {
    return userRole as Role;
  }
  return Role.FREE;
}

export const isAdmin = () => {
  return Role.ADMIN === currentUserRole();
}

export const isDataOperator = () => {
  return Role.DATA_OPERATOR === currentUserRole();
}

export const isUserManager = () => {
  return Role.USER_MANAGER === currentUserRole();
}

export const isOrgUser = () => {
  return Role.ORGANIZATION === currentUserRole();
}

export const isPremium = () => {
  return Role.PREMIUM === currentUserRole();
}

export const isFreeUser = () => {
  return Role.FREE === currentUserRole();
}

export const isOutOfOrganization = () => {
  return isOrgUser() && localStorage.getItem('outOfOrganization') === 'true';
}

export const isAuthorised = () => {
  if (isFreeUser() || isOutOfOrganization()) {
    return false;
  } else if (isPremium() || isAdmin() || isDataOperator() || isUserManager() || isOrgUser()) {
    return true;
  } else {
    logout();
  }
}

export const sendVerificationEmail = async (userData: any) => {
  return postRequest('/auth/send-verification-email', userData);
};

export const getUserId = () => {
  return decryptString(localStorage.getItem('userId'));
}

export const getUserName = () => {
  return decryptString(localStorage.getItem('username'));
}