import { enableCacheBustingForAdmins } from "../app.utils";
import { getRequest, postRequest } from "./request-interceptor.service"

export const fetchDepartmentFile = async (fileId: string) => {
  return await getRequest(`/department-search/department-file/${fileId}`, false, {
    responseType: 'blob',
  });
}

export const fetchDepartmentCategory = async () => {
  return await getRequest('/department-search/department-parents', enableCacheBustingForAdmins());
}

export const fetchDepartmentSubCategoryByCategory = async (departmentId: string) => {
  return await getRequest(`/department-search/department-children/${departmentId}`, enableCacheBustingForAdmins());
}

export const fetchFilesByDepartment = async (departmentId: string) => {
  return await getRequest(`/department-search/department-by-id/${departmentId}`, enableCacheBustingForAdmins());
}

export const fetchFilesByYear = async (params: any) => {
  return await postRequest('/department-search/search-by-year-for-files', params);
}