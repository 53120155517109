import React from "react";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export type PreviewBlogDialogProps = {
  open: boolean;
  onClose: () => void;
  blogContent: string;
  blogTitle: string;
  blogThumb: string;
};

export const PreviewBlog = ({ open, onClose, blogContent, blogTitle, blogThumb }: PreviewBlogDialogProps) => {
  return (
    <Dialog maxWidth='lg' open={open} onClose={onClose}>
      <div className="flex justify-end items-center bg-white border-b border-gray-200 py-2 px-4">
        <IconButton
          onClick={onClose}
          size="small"
          className='action'
          sx={{ color: '#D91E25' }}>
          <HighlightOffIcon fontSize='small' />
        </IconButton>
      </div>
      <DialogContent>
        <div className="flex flex-col max-w-5xl mx-auto gap-2">
          <p className="text-3xl font-semibold text-center mt-5">{blogTitle}</p>
          <img src={blogThumb} alt="Uploaded" className="w-96 mx-auto mt-5 h-auto rounded-md" />
          <div className="mt-5">
            <div dangerouslySetInnerHTML={{
              __html: blogContent,
            }}></div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};