import { getRequest, postRequest } from "./request-interceptor.service";

export const registerOrganization = async (file: string | Blob) => {
  const formData = new FormData();
  formData.append('file', file);
  return await postRequest('/organization/register', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
}

export const fetchOrganizationTypes = async () => {
  return await getRequest('/organization/types');
}

export const fetchOrganizationDetails = async (organizationId: string) => {
  return await getRequest(`/organization/${organizationId}/details`);
}