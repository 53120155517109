import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material';
import Login from './login';
import SignUp from './signup';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { encryptString } from '../../app.utils';
import { useLoader } from '../../providers';
import { useScrollToTop } from '../../app.hooks';
import axios from 'axios';
import { constants } from '../../app.constants';

export const Auth = () => {
  useScrollToTop();

  const { showLoader, hideLoader } = useLoader();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [selectedOption, setSelectedOption] = useState(searchParams.get('current') || 'signin');
  const orgId = searchParams.get('orgId') || '';

  const signinOrg = async () => {
    showLoader();
    try {
      let response = await axios.post(constants.apiUrl + '/auth/org', { orgId });
      if (response.data.user && response.data.token) {
        localStorage.setItem('isAuthenticated', encryptString('true'));
        localStorage.setItem('username', encryptString(response.data.user.name));
        localStorage.setItem('userId', encryptString(response.data.user.id));
        localStorage.setItem('role', encryptString(response.data.user.role));
        localStorage.setItem('token', encryptString(response.data.token));
        localStorage.setItem('isVerified', encryptString((response.data.user.emailVerified).toString()));
        if (response.data.user.emailVerified === true) {
          toast.success('Logged in successfully!');
          window.location.href = '/home';
        }
      }
    } catch {
      toast.error("Failed to login. Please make sure you access EastLaw within your organization's network.");
    } finally {
      hideLoader();
    }
  }

useEffect(() => {
  if (orgId !== '') {
    signinOrg();
  }
}, []);

return (
  <>
    <div className='hidden lg:flex'>
      <div className='flex flex-row w-full h-[calc(100vh-6rem)]'>
        <div className='flex flex-col w-1/2 justify-center items-center h-full bg-gradient-to-t from-[#41090b] to-[#150303]'>
          {selectedOption === 'signin' ? (
            <div className='flex flex-col gap-4 justify-center items-center'>
              <p className='text-white text-3xl text-center'>Don't have an account?<br />Register your account to get started.</p>
              <Button variant='contained' color='primary'
                className='w-[10rem] mt-4' onClick={() => setSelectedOption('signup')}>Register</Button>
            </div>
          ) : (
            <div className='flex flex-col gap-4 justify-center items-center'>
              <p className='text-white text-3xl text-center'>Already have an account?<br />Login to continue.</p>
              <Button variant='contained' color='primary' className='w-[10rem] mt-4' onClick={() => setSelectedOption('signin')}>Login</Button>
            </div>
          )}
        </div>
        <div className='flex flex-col w-1/2 bg-[#f3f2f2] justify-center items-center relative'>
          <div className='flex flex-col h-full justify-center w-4/5 mx-auto'>
            <div className='px-8 pt-8 pb-6 bg-white rounded-xl overflow-auto shadow-xl'>
              {selectedOption === 'signup' ? (
                <SignUp />
              ) : (
                <Login />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='flex lg:hidden bg-gradient-to-t from-[#41090b] to-[#150303] min-h-[calc(100vh-6rem)] flex-col gap-20 py-20 items-center'>
      {selectedOption === 'signin' ? (
        <div className='flex flex-col gap-4 justify-center items-center px-2'>
          <p className='text-white text-3xl text-center'>Don't have an account?<br />Register your account to get started.</p>
          <Button variant='contained' color='primary'
            className='w-[10rem] mt-4' onClick={() => setSelectedOption('signup')}>Register</Button>
        </div>
      ) : (
        <div className='flex flex-col gap-4 justify-center items-center px-2'>
          <p className='text-white text-3xl text-center'>Already have an account?<br />Login to continue.</p>
          <Button variant='contained' color='primary' className='w-[10rem] mt-4' onClick={() => setSelectedOption('signin')}>Login</Button>
        </div>
      )}
      <div className='px-4 pt-4 pb-3 bg-white rounded-xl overflow-auto shadow-xl'>
        {selectedOption === 'signup' ? (
          <SignUp />
        ) : (
          <Login />
        )}
      </div>
    </div>
  </>
);
};
