import { Delete, Launch } from "@mui/icons-material";
import { Tooltip, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { isAuthorised } from "../app.utils";
import { constants } from "../app.constants";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserId } from "../services/auth.service";
import { deleteSearchHistory, fetchSearchHistory } from "../services/search-history.service";
import DataTable, { Action, Column } from "../components/DataTable";
import { HistoryItem } from "../app.models";
import { useLoader } from "../providers";

export const SavedSearches = () => {
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useLoader();

  const [savedSearches, setSavedSearches] = useState([]);
  const [historyPageNo, setHistoryPageNo] = useState(1);
  const [totalHistoryPages, setTotalHistoryPages] = useState(1);
  const [filteredHistory, setFilteredHistory] = useState([]);
  const [historySearchInput, setHistorySearchInput] = useState("");

  const columns: Column[] = [
    {
      key: 'typeName',
      label: 'Screen',
      renderCell: (row: HistoryItem) => (
        <span className='uppercase'>{row.typeName || '-'}</span>
      )
    },
    {
      key: 'createdOn',
      label: 'Date',
      renderCell: (row: HistoryItem) => (
        <span>{row.createdOn?.toString().split('T')[0] || '-'}</span>
      )
    },
    { key: 'label', label: 'Search label', maxChars: 40 },
    {
      key: 'url',
      label: 'URL',
      maxChars: 60,
    }
  ];

  const handlePageChange = (newPage: number) => {
    setHistoryPageNo(newPage);
  };

  const searchHistoryFilter = (searchValue: any) => {
    setHistorySearchInput(searchValue);
    if (historySearchInput !== "") {
      const filteredData = savedSearches.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(historySearchInput.toLowerCase());
      });
      setFilteredHistory(filteredData);
    }
  };

  const getHistory = async () => {
    showLoader();

    try {
      const response: any = await fetchSearchHistory({
        pageNo: historyPageNo,
        pageSize: constants.pageSize,
        userId: getUserId()
      });

      if (response) {
        setSavedSearches(response.data);
        setTotalHistoryPages(response.totalPages);
      }
    } catch {
      toast.error('Failed to fetch history data.');
    } finally {
      hideLoader();
    }
  };

  const deleteHistoryHandler = async (item: any) => {
    const userId = getUserId();
    const historyId = item.id;

    try {
      await deleteSearchHistory(userId, historyId);
      toast.success('History deleted successfully.');
      await getHistory();
    } catch {
      toast.error('Failed to delete search history.');
    }
  };

  useEffect(() => {
    getHistory();
  }, [historyPageNo]);

  useEffect(() => {
    setFilteredHistory(savedSearches);
  }, [savedSearches]);

  const handleVisitHistory = (item: HistoryItem) => {
    navigate(item.url);
  }

  const actions: Action[] = [
    {
      label: 'Visit',
      handler: handleVisitHistory,
      icon: <Launch fontSize='small' />,
    },
    {
      label: 'Delete',
      handler: deleteHistoryHandler,
      icon: <Delete fontSize='small' />,
    }
  ];

  return (
    <div className="flex flex-col gap-3 rounded-container">
      <div className="flex flex-row justify-between items-center pb-0">
        <p className="text-2xl font-bold">Saved Searches</p>
        <Tooltip title={!isAuthorised() ? constants.tooltips.upgradePlan : ''} placement={'left'}>
          <span>
            <TextField
              sx={{
                minWidth: 400,
              }}
              disabled={!isAuthorised()}
              size="small"
              type="text"
              placeholder="Search here"
              onChange={(e) => searchHistoryFilter(e.target.value)}
            />
          </span>
        </Tooltip>
      </div>
      <DataTable
        columns={columns}
        data={filteredHistory}
        pageNo={historyPageNo}
        totalPages={totalHistoryPages}
        onPageChange={handlePageChange}
        actions={actions}
        heightAdjustment={22.2}
        pageSize={Number.parseInt(constants.pageSize)}
      />
    </div>
  );
}