import { Check } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useState } from "react";
import { decryptString } from "../app.utils";
import { useDialog } from "../providers";
import { Plan } from "../app.model";
import { ConfirmationPrompt } from "../dialogs";
import { toast } from "react-toastify";
import { upgradeUserPlanRequestEmail } from "../services/user.service";

export const SubscriptionCard = ({ plan }: { plan: Plan }) => {
  const userPlan = decryptString(localStorage.getItem('userPlanId'));
  const username = decryptString(localStorage.getItem('username') || '');
  const [upgradeRequestSent, setUpgradeRequestSent] = useState(localStorage.getItem('upgradeRequestSent') === 'true');

  const { openDialog, closeDialog } = useDialog();

  const openPremiumPlanRequestDialog = (selectedPlan: Plan) => {
    openDialog(ConfirmationPrompt, {
      onClose: () => {
        closeDialog();
      },
      title: `Plan Upgrade Request Confirmation`,
      message: `Are you sure you want to request an upgrade to the ${selectedPlan.name} plan?`,
      component: (
        <div className="flex flex-col gap-1 break-words bg-yellow-100 border border-yellow-400 rounded py-1 px-2 mt-4 text-sm">
          <p className="font-bold">Important:</p>
          <p>After you click "Yes", you will receive an email with instructions on the next steps. Please check your email and follow the instructions to complete the upgrade process.</p>
        </div>
      ),
      onConfirm: () => {
        requestUpgrade(selectedPlan.id);
      }
    });
  }

  const requestUpgrade = async (selectedPlan: string) => {
    try {
      await upgradeUserPlanRequestEmail(username, selectedPlan);
      toast.success('You have requested an upgrade to Premium. Please follow instructions in the email received.');
      localStorage.setItem('upgradeRequestSent', 'true');
      setUpgradeRequestSent(true);
      window.location.reload();
    } catch(error) {
      toast.error('Failed to send email. Please try again.')
      setUpgradeRequestSent(false);
    }
  }

  const getButtonText = () => {
    if (plan.id === userPlan)
      return 'Subscribed';
    if (upgradeRequestSent)
      return 'Upgrade Requested';
    return 'Request Upgrade';
  }

  return (
    <div className={'flex flex-col w-[23rem] mb-3'}>
      <div className={`flex flex-col gap-2 items-center bg-white min-h-[29rem] ${plan.id === userPlan ? 'border-[3px] border-[#D91E25]' : 'border border-slate-200'} rounded-xl pt-3 shadow-lg`}>
        <div className="flex flex-col gap-2 px-4 py-3 items-center w-full">
          {plan.name === 'Free Users' ? (
            <p className="text-[1.5rem] font-bold py-[2.3rem]">
              Free Tier
            </p>
          ) : (
            <>
              <p className="text-[1.5rem] font-bold">
                {plan.name}
              </p>
              <p className="text-4xl font-normal text-center tracking-tight">
                <span className="text-lg font-light">Rs.</span> {plan.price}
              </p>
              <span className="text-sm font-light">for {plan.activationPeriod} days.</span>
            </>
          )}
          <Button
            color='primary'
            variant='outlined'
            onClick={() => openPremiumPlanRequestDialog(plan)}
            className='w-full'
            disabled={upgradeRequestSent || plan.id === userPlan || plan.name === 'Free Users'}>
            {getButtonText()}
          </Button>
        </div>
        <div className="w-full h-full rounded-b-xl bg-[#f6f8fa] px-6 py-4">
          <p className="mb-3 font-semibold">Includes</p>
          <ul >
            {plan.features.map((feature: any) => (
              <li key={feature} className="flex flex-row justify-start mb-3">
                <Check fontSize="small" className="mr-2 text-green-600" />
                <p className="font-normal text-sm">{feature}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};