import axios, { AxiosRequestConfig } from 'axios';
import { decryptString } from '../app.utils';
import { logout } from './auth.service';

// Function to attach cache-busting query parameter
const attachCacheBustingQueryParam = (url: string): string => {
  const separator = url.includes('?') ? '&' : '?';
  const cacheBustingQueryParam = `_=${new Date().getTime()}`;
  return `${url}${separator}${cacheBustingQueryParam}`;
};

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_NEST_URL,
});

// Request interceptor to handle token authorization
axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    // Attach the Authorization header with the Bearer token
    const token = decryptString(localStorage.getItem('token'));
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle 401 Unauthorized
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      logout();
    }
    return Promise.reject(error);
  }
);

// Function to handle GET requests with optional cache-busting
export const getRequest = async <T>(
  url: string,
  enableCacheBustingForAdmins: boolean = false,
  config?: AxiosRequestConfig,
): Promise<T> => {
  try {
    if (enableCacheBustingForAdmins) {
      url = attachCacheBustingQueryParam(url);
    }
    const response = await axiosInstance.get<T>(url, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to handle POST requests
export const postRequest = async <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<T> => {
  try {
    const response = await axiosInstance.post<T>(url, data, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to handle PATCH requests
export const patchRequest = async <T>(
  url: string,
  data: any,
  config?: AxiosRequestConfig
): Promise<T> => {
  try {
    const response = await axiosInstance.patch<T>(url, data, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to handle PUT requests
export const putRequest = async <T>(
  url: string,
  data: any,
  config?: AxiosRequestConfig
): Promise<T> => {
  try {
    const response = await axiosInstance.put<T>(url, data, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to handle DELETE requests
export const deleteRequest = async <T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<T> => {
  try {
    const response = await axiosInstance.delete<T>(url, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};
