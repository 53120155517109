import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { CircularProgress, Box } from "@mui/material";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface PDFViewerProps {
  blob: Blob;
}

export const PDFViewer: React.FC<PDFViewerProps> = ({ blob }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true); // New loading state

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setLoading(false); // Hide loader when PDF is successfully loaded
  };

  const onDocumentLoadError = (err: any) => {
    console.error(err);
    setLoading(false); // Hide loader even if there's an error
  };

  return (
    <Box position="relative">
      {loading && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={1}
          bgcolor="white"
        >
          <CircularProgress color="primary" />
        </Box>
      )}
      <Document
        file={blob}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
        loading=""
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            width={900}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        ))}
      </Document>
    </Box>
  );
};
