import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import { toast } from "react-toastify";
import { encryptString } from "../../app.utils";
import { useDialog } from "../../providers/dialog.provider";
import { useLoader } from "../../providers";
import { PasswordReset, RequestAccountActivation } from "../../dialogs";
import axios from "axios";
import { constants } from "../../app.constants";

const Login = () => {
  const { openDialog, closeDialog } = useDialog();
  const [userEmail, setUserEmail] = useState("");
  const [userPass, setUserPass] = useState("");
  const [formErrors, setFormErrors] = useState<any>({});
  const [touchedFields, setTouchedFields] = useState<any>({});
  const { showLoader, hideLoader } = useLoader();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [loginMethod, setLoginMethod] = useState('');

  const handleInputChange = (event: any) => {
    let errors: any = {};
    const { name, value } = event.target;
    switch (name) {
      case 'userEmail': {
        setUserEmail(value);
        if (!value) {
          errors.userEmail = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          errors.userEmail = 'Email is invalid';
        } else {
          delete formErrors.userEmail;
        }
        break;
      }
      case 'userPass': {
        setUserPass(value);
        if (!value) {
          errors.userPass = 'Password is required';
        }
        else if (value.length < 8) {
          errors.userPass = 'Password must be at least 8 characters';
        } else {
          delete formErrors.userPass;
        }
        break;
      }
      default:
        break;
    }
    setFormErrors((prevErrors: any) => {
      return { ...prevErrors, ...errors };
    });
  };

  const handleFieldBlur = (event: any) => {
    const { name } = event.target;
    setTouchedFields((prevTouched: any) => {
      return { ...prevTouched, [name]: true };
    });
  };

  const openReportProblemDialog = () => {
    openDialog(RequestAccountActivation, {
      onClose: () => {
        closeDialog();
      }
    });
  };

  const signInUser = async (e: any) => {
    e.preventDefault();
    if (Object.keys(formErrors).length === 0) {
      try {
        showLoader();
        const response: any = await axios.post(constants.apiUrl + '/auth/signin', { email: userEmail, password: userPass }, {
          withCredentials: true,
        });
        if (response.data) {
          // On Success save user info and goto Dashboard screen
          if (response.data.user && response.data.token) {
            localStorage.setItem('isAuthenticated', encryptString('true'));
            localStorage.setItem('username', encryptString(response.data.user.name));
            localStorage.setItem('userId', encryptString(response.data.user.id));
            localStorage.setItem('role', encryptString(response.data.user.role));
            localStorage.setItem('userPlanId', encryptString(response.data.user.planId));
            localStorage.setItem('token', encryptString(response.data.token));
            localStorage.setItem('isVerified', encryptString((response.data.user.emailVerified).toString()));
            if (response.data.user.emailVerified === true) {
              toast.success('Logged in successfully!');
              window.location.href = '/home';
            }
            else {
              toast.info('Email is not verified; please verify email to continue.');
            }
          }
        }
      } catch (error: any) {
        const errorData = error.response?.data;
        if (errorData) {
          if (errorData.statusCode == 406) {
            toast.error(errorData.message);
            openReportProblemDialog();
          } else {
            toast.error("Incorrect email or password.");
          }
        } else {
          toast.error("Something went wrong.");
        }
      } finally {
        hideLoader();
      }
    }
  };

  const onNext = async (e: any) => {
    e.preventDefault();
    if (userEmail !== '') {
      showLoader();
      try {
        const response: any = await axios.post(constants.apiUrl + '/auth/methods', { email: userEmail }, {
          withCredentials: true,
        });
        if (response.data) {
          if (response.data.user && response.data.token) {
            localStorage.setItem('isAuthenticated', encryptString('true'));
            localStorage.setItem('username', encryptString(response.data.user.name));
            localStorage.setItem('userId', encryptString(response.data.user.id));
            localStorage.setItem('role', encryptString(response.data.user.role));
            localStorage.setItem('token', encryptString(response.data.token));
            localStorage.setItem('isVerified', encryptString((response.data.user.emailVerified).toString()));
            if (response.data.user.emailVerified === true) {
              toast.success('Logged in successfully!');
              window.location.href = '/home';
            }
            else {
              toast.info('Email is not verified; please verify email to continue.');
            }
          }
        }
      } catch(err: any) {
        if (err.response.status === 403) {
          setIsUserLoggedIn(false);
          setLoginMethod('password');
        } else {
          toast.error("Something went wrong.");
        }
      } finally {
        hideLoader();
      }
    }
  }

  const openPasswordResetDialog = () => {
    openDialog(PasswordReset, {
      onClose: () => {
        closeDialog();
      }
    });
  };

  return (
    <form className="flex flex-col bg-white" onSubmit={signInUser}>
      <div className="flex flex-col gap-2 w-full h-full">
        <p className="text-3xl font-bold mt-2 mb-5 gradient-text-red">{!isUserLoggedIn && loginMethod === 'password' ? 'Please enter your password' : 'Login to your account'}</p>
        <label className='text-gray-500'>Email<b className="text-primary ml-1">*</b></label>
        <TextField
          size="small"
          name="userEmail"
          placeholder="Enter your email address"
          value={userEmail}
          onChange={handleInputChange}
          onBlur={handleFieldBlur}
          error={!!(formErrors.userEmail && touchedFields.userEmail)}
          helperText={touchedFields.userEmail ? formErrors.userEmail : ""}
          required
        />
        {
          !isUserLoggedIn && loginMethod === 'password' && (
            <>
              <label className='text-gray-500'>Password<b className="text-primary ml-1">*</b></label>
              <TextField
                size="small"
                required
                id="userPass"
                name="userPass"
                type="password"
                placeholder="Enter your password"
                value={userPass}
                onChange={handleInputChange}
                onBlur={handleFieldBlur}
                error={!!(formErrors.userPass && touchedFields.userPass)}
                helperText={touchedFields.userPass ? formErrors.userPass : ""}
              />
            </>
          )
        }
      </div>
      <div className="mt-5">
        {
          !isUserLoggedIn && loginMethod === 'password' ? (
            <Button
              type="submit"
              className="w-full mt-4"
              disabled={(userEmail.length === 0 || userPass.length === 0) || Object.keys(formErrors).length > 0}
              variant="contained"
              color='primary'
              onClick={signInUser}
            >
              Login
            </Button>
          ) : (
            <Button
              type="submit"
              className="w-full mt-4"
              disabled={userEmail.length === 0 || Object.keys(formErrors).length > 0}
              variant="contained"
              color='primary'
              onClick={onNext}
            >
              Next
            </Button>
          )
        }
        {
          !isUserLoggedIn && loginMethod === 'password' && (
            <div className="flex flex-row justify-end w-full mt-2">
              <Button onClick={openPasswordResetDialog}>
                Forgot password?
              </Button>
            </div>
          )}

      </div>
    </form>
  );
};

export default Login;
