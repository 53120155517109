import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDialog } from "./providers";
import { ForbiddenPrompt } from "./dialogs";
import { decryptString, encryptString } from "./app.utils";

// Custom hook to handle scrolling to top on pathname change
export const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

export const useForbiddenPrompt = () => {
  const { openDialog, closeDialog } = useDialog();

  const openForbiddenPrompt = () => {
    localStorage.setItem('outOfOrganization', 'true');
    openDialog(ForbiddenPrompt, {
      onClose: () => {
        closeDialog();
      },
      showLogout: true,
    });
  };

  return openForbiddenPrompt;
};


export const useEncryptedSearchParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);

  const getParam = (key: string): string | null => {
    const encryptedValue = searchParams.get(key);
    return encryptedValue ? decryptString(encryptedValue) : null;
  };

  const setParam = (key: string, value: string) => {
    const encryptedValue = encryptString(value);
    searchParams.set(key, encryptedValue);
    navigate({ search: searchParams.toString() });
  };

  const deleteParam = (key: string) => {
    searchParams.delete(key);
    navigate({ search: searchParams.toString() });
  };

  return {
    getParam,
    setParam,
    deleteParam,
    searchParams,
  };
};
