import React, { useEffect, useState } from 'react';
import { RemoveRedEye, Delete } from '@mui/icons-material';
import { Tooltip, TextField } from '@mui/material';
import { BookmarkTypes } from '../app.enums';
import { isAuthorised } from '../app.utils';
import { constants } from '../app.constants';
import { useNavigate } from 'react-router-dom';
import { getUserId } from '../services/auth.service';
import { toast } from 'react-toastify';
import { useDialog, useLoader } from '../providers';
import { DepartmentDocumentPreview, StatuteDocumentPreview } from '../dialogs';
import { deleteBookmark, fetchBookmarks } from '../services/bookmarks.service';
import DataTable, { Action, Column } from '../components/DataTable';
import { Bookmark } from '../app.models';

export const Bookmarks = () => {
  const { openDialog, closeDialog } = useDialog();
  const { showLoader, hideLoader } = useLoader();

  const navigate = useNavigate();
  const [bookmarks, setBookmarks] = useState([]);
  const [bookmarkPageNo, setBookmarkPageNo] = useState(1);
  const [totalBookmarkPages, setTotalBookmarkPages] = useState(1);
  const [filteredBookmarks, setFilteredBookmarks] = useState([]);
  const [bookmarkSearchInput, setBookmarkSearchInput] = useState('');

  const columns: Column[] = [
    {
      key: 'typeName',
      label: 'Name',
      renderCell: (row: Bookmark) => (
        <span className='uppercase'>{row.typeName || '-'}</span>
      )
    },
    {
      key: 'createdOn',
      label: 'Date',
      renderCell: (row: Bookmark) => (
        <span>{row.createdOn?.toString().split('T')[0] || '-'}</span>
      )
    },
    { key: 'detail', label: 'Details', maxChars: 25 },
    { key: 'header', label: 'Title', maxChars: 55 },
  ];

  const handlePageChange = (newPage: number) => {
    setBookmarkPageNo(newPage);
  };

  const searchBookmarkFilter = (searchValue: any) => {
    setBookmarkSearchInput(searchValue);
    if (bookmarkSearchInput !== '') {
      const filteredData = bookmarks.filter((item) => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(bookmarkSearchInput.toLowerCase());
      });
      setFilteredBookmarks(filteredData);
    }
  };

  const getBookmarks = async () => {
    showLoader();
    try {
      const response: any = await fetchBookmarks({
        pageNo: bookmarkPageNo,
        pageSize: constants.pageSize,
        userId: getUserId(),
      });

      if (response) {
        setBookmarks(response.data);
        setTotalBookmarkPages(response.totalPages);
      }
    } catch {
      toast.error('Failed to fetch bookmarks');
    } finally {
      hideLoader();
    }
  };

  const openJudgment = (caseId: string) => {
    navigate('/cases/judgment?judgmentId=' + caseId);
  };

  const openStatuteDoc = (item: Bookmark) => {
    if (item.targetId !== '' && item.header !== '') {
      openDialog(StatuteDocumentPreview, {
        onClose: () => {
          closeDialog();
        },
        statute: {
          selectedStatute: item.targetId,
          selectedStatuteTitle: item.header,
          selectedStatuteAct: item.detail || '',
        }
      });
    }
  }

  const openDepartmentFile = (item: Bookmark) => {
    if (item.targetId !== '' && item.detail !== '' && item.header !== '') {
      openDialog(DepartmentDocumentPreview, {
        onClose: () => {
          closeDialog();
        },
        departmentData: {
          selectedFile: item.detail,
          selectedFileId: item.targetId,
          selectedDepartTitle: item.header,
        }
      });
    }
  }

  const deleteBookmarkHandler = async (item: Bookmark) => {
    const userId = getUserId();
    const bookmarkId = item.id;

    try {
      await deleteBookmark(userId, bookmarkId);
      toast.success('Bookmark deleted successfully.');
      getBookmarks();
    } catch {
      toast.error('Failed to delete bookmark.');
    }
  };

  const handleViewBookmarkItem = (item: Bookmark) => {
    switch (item.typeName) {
      case BookmarkTypes.STATUTE: {
        openStatuteDoc(item);
        break;
      }
      case BookmarkTypes.CASE: {
        openJudgment(item.targetId);
        break;
      }
      case BookmarkTypes.DEPARTMENT: {
        openDepartmentFile(item);
        break;
      }
    }
  }

  const actions: Action[] = [
    {
      label: 'View',
      handler: handleViewBookmarkItem,
      icon: <RemoveRedEye fontSize='small' />,
    },
    {
      label: 'Delete',
      handler: deleteBookmarkHandler,
      icon: <Delete fontSize='small' />,
    }
  ];

  useEffect(() => {
    getBookmarks();
  }, [bookmarkPageNo]);

  useEffect(() => {
    setFilteredBookmarks(bookmarks);
  }, [bookmarks]);

  return (
    <div className='flex flex-col gap-3 rounded-container'>
      <div className='flex flex-row justify-between items-center pb-0'>
        <p className='text-2xl font-bold'>Bookmarks</p>
        <Tooltip title={!isAuthorised() ? constants.tooltips.upgradePlan : ''} placement={'left'}>
          <span>
            <TextField
              sx={{
                minWidth: 400,
              }}
              disabled={!isAuthorised()}
              size='small'
              type='text'
              placeholder='Search here'
              onChange={(e) => searchBookmarkFilter(e.target.value)}
            />
          </span>
        </Tooltip>
      </div>

      <DataTable
        columns={columns}
        data={filteredBookmarks}
        pageNo={bookmarkPageNo}
        totalPages={totalBookmarkPages}
        onPageChange={handlePageChange}
        actions={actions}
        heightAdjustment={22.2}
        pageSize={Number.parseInt(constants.pageSize)}
      />
    </div>
  );
}